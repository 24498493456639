import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import i18n from "./../i18n";
import store from "./../store";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = process.env.VUE_APP_BASE_API ? process.env.VUE_APP_BASE_API : "";

Vue.use(VueAxios, axios);

Vue.axios.interceptors.response.use(function(response) {
  const config = response.config;
  const messages = config.messages;
  const notification = config.notification || {};
  const status = response.status;
  if (messages) {
    if (messages[status]) {
      if (typeof messages[status] === "string") {
        store.dispatch("notification/notify", {
          message: messages[status],
          ...notification
        });
      } else if (typeof messages[status] === "function") {
        store.dispatch("notification/notify", {
          message: messages[status](response.data),
          ...notification
        });
      }
    }
  }
  return response;
},
function(error) {
  const config = error.config;
  const messages = config.messages;
  const notification = config.notification || {};
  if (error && error.response) {
    const status = error.response.status;
    if (messages) {
      if (messages[status]) {
        if (typeof messages[status] === "boolean") {
          let message = "errors.error_occured";
          switch(status) {
            case 400:
              message = "errors.bad_request";
              break;
            case 401:
              message = "errors.unauthorized"
              break;
            case 403:
              message = "errors.forbidden"
              break;
            case 404:
              message = "errors.not_found";
              break;
            case 405:
              message = "errors.method_not_allowed"
              break;
          }
          store.dispatch("notification/notify", {
            message: i18n.t(message),
            ...notification
          });
        } else if (typeof messages[status] === "string") {
          store.dispatch("notification/notify", {
            message: messages[status],
            ...notification
          });
        } else if (typeof messages[status] === "function") {
          const message = messages[status](error.response.data);
          if (message) {
            store.dispatch("notification/notify", {
              message: messages[status](error.response.data),
              ...notification
            });
          }
        }
      }
    }
  }
  return Promise.reject(error);
})
