import Vue from "vue";
import auth from "@websanova/vue-auth/src/v2.js";
import authBearer from "./authbearer";
import httpAxios from "@websanova/vue-auth/src/drivers/http/axios.1.x.js";
import routerVueRouter from "@websanova/vue-auth/src/drivers/router/vue-router.2.x.js";

const tokenName = process.env.VUE_APP_TOKEN_NAME || "_security_guinness_bar";

Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router: Vue.router
  },
  drivers: {
    auth: authBearer,
    http: httpAxios,
    router: routerVueRouter
  },
  options: {
    rolesKey: "role",
    stores: ["cookie"],
    cookie: {
      Secure: process.env.VUE_APP_COOKIE_SECURE == "1" ? true : false,
      Expires: parseInt(process.env.VUE_APP_COOKIE_EXPIRES || "86400000"),
      SameSite: "Strict"
    },
    tokenDefaultKey: tokenName,
    authRedirect: { path: "/login" },
    fetchData: {
      url: "/api/v1/users/me",
      method: "GET",
      enabled: true
    },
    loginData: {
      url: "/api/v1/login",
      method: "POST",
      redirect: "/",
      fetchUser: true
    },
    refreshData: {
      url: "/api/v1/refresh-token",
      method: "POST",
      enabled: true,
      interval: process.env.VUE_APP_RERESH_TOKEN_INTERVAL ? parseFloat(process.env.VUE_APP_RERESH_TOKEN_INTERVAL) : 15,
      transformRequest: function(data) {
        data = JSON.stringify({
          refresh: Vue.auth.token(tokenName).split("||")[1]
        });
        return data;
      }
    },
    logoutData: {
      url: "/api/v1/logout",
      method: "POST",
      redirect: "/login",
      makeRequest: true,
      transformRequest: function(data) {
        data = JSON.stringify({
          refresh: Vue.auth.token(tokenName).split("||")[1]
        });
        return data;
      }
    },
    parseUserData: data => {
      return data;
    }
  }
});
