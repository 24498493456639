import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "./../views/layout.vue";
import i18n, { loadLanguageAsync, allowedLanguages } from "./../i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:locale(fr|en)?",
    component: Layout,
    meta: {
      auth: true,
    },
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("./../views/dashboard.vue"),
      },
      {
        path: "resellers",
        name: "reseller",
        component: () => import("./../views/resellers/index.vue"),
      },
      {
        path: "resellers/add",
        name: "reseller-add",
        component: () => import("./../views/resellers/add.vue"),
        meta: {
          auth: {
            roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
          },
        },
      },
      {
        path: "resellers/upload",
        name: "reseller-upload",
        component: () => import("./../views/resellers/upload.vue"),
        meta: {
          auth: {
            roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
          },
        },
      },
      {
        path: "resellers/edit/:id(\\d+)",
        name: "reseller-edit",
        component: () => import("./../views/resellers/edit.vue"),
        meta: {
          auth: {
            roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
          },
        },
      },
      {
        path: "promotions",
        name: "promotion",
        component: () => import("./../views/promotions/index.vue"),
      },
      {
        path: "promotions/add",
        name: "promotion-add",
        component: () => import("./../views/promotions/add.vue"),
        meta: {
          auth: {
            roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
          },
        },
      },
      {
        path: "promotions/upload",
        name: "promotion-upload",
        component: () => import("./../views/promotions/upload.vue"),
        meta: {
          auth: {
            roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
          },
        },
      },
      {
        path: "promotions/edit/:id(\\d+)",
        name: "promotion-edit",
        component: () => import("./../views/promotions/edit.vue"),
        meta: {
          auth: {
            roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
          },
        },
      },
      {
        path: "frauds",
        name: "fraud",
        component: () => import("./../views/frauds/index.vue"),
        meta: {
          auth: {
            roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
          },
        },
      },
      {
        path: "participations",
        name: "participation",
        component: () => import("./../views/participations/index.vue"),
      },
      {
        path: "users",
        name: "user",
        component: () => import("./../views/users/index.vue"),
        meta: {
          auth: {
            roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
          },
        },
      },
      {
        path: "users/add",
        name: "user-add",
        component: () => import("./../views/users/add.vue"),
        meta: {
          auth: {
            roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
          },
        },
      },
      {
        path: "users/edit/:id(\\d+)",
        name: "user-edit",
        component: () => import("./../views/users/edit.vue"),
        meta: {
          auth: {
            roles: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
          },
        },
      },
      {
        path: "sessions",
        name: "session",
        component: () => import("./../views/sessions/index.vue"),
      },
    ],
  },
  {
    path: "/:locale(fr|en)?",
    component: {
      template: "<router-view />",
    },
    children: [
      {
        path: "login",
        component: () => import("./../views/security/login.vue"),
        name: "login",
      },
      {
        path: "logout",
        component: () => import("./../views/security/logout.vue"),
        name: "logout",
      },
    ],
  },
  {
    path: "/404",
    name: "404",
    component: () => import("./../views/errors/404.vue"),
  },
  {
    path: "/403",
    name: "403",
    component: () => import("./../views/errors/403.vue"),
  },
  { path: "*", redirect: "/404" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// manage locale
router.beforeEach(async (to, from, next) => {
  let excludedRoutes = ["404", "403"];
  let locale = "",
    localeUrlSegment = to.path.split("/"),
    currentLocale = i18n.locale,
    redirect = false,
    ignoreLocale = false;

  // Get locale from path
  localeUrlSegment.shift();
  locale = localeUrlSegment[0];

  // Locale fallback
  if (!allowedLanguages.includes(locale)) {
    locale = currentLocale;
    redirect = true;
  }

  if(excludedRoutes.includes(to.name)) {
    ignoreLocale = true;
  }

  // Move on the next hook (render component view)
  if (redirect && !ignoreLocale) {
    // Set locale
    await loadLanguageAsync(locale);
    to.params.locale = locale;
    next(to);
  } else {
    next();
  }
});

Vue.router = router;
export default router;
