<template>
  <v-app id="app">
    <v-snackbar
      :dark="notification.dark"
      :light="notification.light"
      :absolute="notification.absolute"
      :multi-line="notification.multiLine"
      :color="notification.color"
      :bottom="notification.bottom"
      :left="notification.left"
      :right="notification.right"
      :top="notification.top"
      :centered="notification.centered"
      v-model="notification.status"
      :timeout="-1"
    >
      {{ notification.message }}
      <template v-slot:action>
        <v-icon @click="closeNotification()" right :class="{'white--text': notification.dark}">mdi-close</v-icon>
      </template>
    </v-snackbar>
    <template v-if="$auth.ready()">
      <router-view></router-view>
    </template>
    <template v-else>
      <div class="text-center mt-5">
        <v-progress-circular indeterminate color="secondary" width="4" size="60"></v-progress-circular>
      </div>
    </template>
  </v-app>
</template>


<style lang="scss">
@import "./sass/app.scss";
@import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar";
</style>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  created() {
    // set moment locale
    Vue.moment().locale(this.$vuetify.lang.current);
  },
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$t("app.description"),
      titleTemplate: "%s - Guinnes Promo Automation"
    };
  },
  methods: {
    ...mapActions({
      closeNotification: "notification/close"
    })
  },
  computed: {
    ...mapGetters({ notification: "notification/notification" })
  }
};
</script>
