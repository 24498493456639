<template>
  <v-app class="app bg-color">
    <v-app-bar app color="fifth" clipped-left dark>
      <v-app-bar-nav-icon @click="drawer = !drawer">
        <template>
          <v-icon color="third">mdi-text</v-icon>
        </template>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="pl-1 app-title">
        <span class="secondary-font third--text">Guinness Bar Automation</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y left bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-avatar size="40">
              <v-img
                :src="require('@/assets/img/user.png')"
                :alt="$auth.user().username"
              ></v-img>
            </v-avatar>
          </v-btn>
        </template>
        <v-list-item-title>
          <v-list class="py-1">
            <v-list-item>
              <v-list-item-avatar size="40">
                <img
                  :src="require('@/assets/img/user.png')"
                  :alt="$auth.user().username"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-bold grey--text text--darken-4">{{
                    fullname
                  }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $auth.user().username }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'logout' }">
              <v-list-item-title>
                <span class="text-body-1">{{ $t("menu.logout") }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-list-item-title>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      class="navigation"
      color="fourth"
      app
      clipped
      v-model="drawer"
      dark
    >
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        :style="{ height: windowHeight + 'px' }"
      >
        <div>
          <v-list dark>
            <v-list-item>
              <v-list-item-avatar size="50">
                <v-img :src="require('@/assets/img/user.png')"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-bold">{{
                    fullname
                  }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('users.fields.role.' + $auth.user().role) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list dark flat dense nav subheader class="pt-4">
            <v-list-item
              link
              :to="$i18n.getRouteLink('dashboard')"
              class="mb-2"
            >
              <v-list-item-icon>
                <v-icon :class="{ 'secondary--text': isRoute('dashboard') }"
                  >mdi-view-dashboard-outline</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  :class="{ 'secondary--text': isRoute('dashboard') }"
                  >{{ $t("menu.dashboard") }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              :to="$i18n.getRouteLink('reseller')"
              class="mb-2"
            >
              <v-list-item-icon>
                <v-icon :class="{ 'secondary--text': isRoute(['reseller', 'reseller-add', 'reseller-edit', 'reseller-upload']) }"
                  >mdi-domain</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  :class="{ 'secondary--text': isRoute(['reseller', 'reseller-add', 'reseller-edit', 'reseller-upload']) }"
                  >{{ $t("menu.reseller") }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              :to="$i18n.getRouteLink('promotion')"
              class="mb-2"
            >
              <v-list-item-icon>
                <v-icon :class="{ 'secondary--text': isRoute(['promotion', 'promotion-add', 'promotion-edit', 'promotion-upload']) }"
                  >mdi-sale</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  :class="{ 'secondary--text': isRoute(['promotion', 'promotion-add', 'promotion-edit', 'promotion-upload']) }"
                  >{{ $t("menu.promotion") }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              :to="$i18n.getRouteLink('participation')"
              class="mb-2"
            >
              <v-list-item-icon>
                <v-icon :class="{ 'secondary--text': isRoute(['participation']) }"
                  >mdi-hand</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  :class="{ 'secondary--text': isRoute(['participation']) }"
                  >{{ $t("menu.participation") }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              :to="$i18n.getRouteLink('session')"
              class="mb-2"
              v-if="$auth.check(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])"
            >
              <v-list-item-icon>
                <v-icon :class="{ 'secondary--text': isRoute(['session']) }"
                  >mdi-tablet-cellphone</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  :class="{ 'secondary--text': isRoute(['session']) }"
                  >{{ $t("menu.session") }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              :to="$i18n.getRouteLink('fraud')"
              class="mb-2"
              v-if="$auth.check(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])"
            >
              <v-list-item-icon>
                <v-icon :class="{ 'secondary--text': isRoute(['fraud']) }"
                  >mdi-cancel</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  :class="{ 'secondary--text': isRoute(['fraud']) }"
                  >{{ $t("menu.fraud") }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              :to="$i18n.getRouteLink('user')"
              class="mb-2"
              v-if="$auth.check(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])"
            >
              <v-list-item-icon>
                <v-icon :class="{ 'secondary--text': isRoute(['user', 'user-add', 'user-edit']) }"
                  >mdi-account-multiple-outline</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  :class="{ 'secondary--text': isRoute(['user', 'user-add', 'user-edit']) }"
                  >{{ $t("menu.user") }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </perfect-scrollbar>
    </v-navigation-drawer>
    <v-main>
      <v-container fluid class="pa-5">
        <router-view></router-view>
      </v-container>
    </v-main>
    <footer-app></footer-app>
  </v-app>
</template>

<script>
import FooterApp from './../components/commons/footer';
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

export default {
  mounted: function () {
    let self = this;
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight - 70;

    this.$nextTick(function () {
      window.addEventListener("resize", function () {
        self.windowHeight = window.innerHeight - 70;
      });
      window.addEventListener("resize", function () {
        self.windowWidth = window.innerWidth;
      });
    });
  },
  data: () => ({
    drawer: true,
    windowHeight: null,
    windowWidth: null,
  }),
  methods: {
    isRoute(routes) {
      return routes.includes(this.$route.name);
    },
  },
  computed: {
    fullname() {
      if(this.$auth.user()) {
        const user = this.$auth.user();
        return `${user.profile.firstName} ${user.profile.lastName}`
      }
      return null;
    }
  },
  components: {
    PerfectScrollbar,
    FooterApp
  }
}
</script>