import VueJS from "vue";
const exceljs = require("exceljs");

const ExcelJS = {};

ExcelJS.install = function(Vue) {
  if (!Vue.$exceljs) {
    Vue.$exceljs = exceljs;
    Object.defineProperties(Vue.prototype, {
      $exceljs: {
        get() {
          return exceljs;
        }
      }
    });
  }
};

VueJS.use(ExcelJS);