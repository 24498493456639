export default {
  namespaced: true,
  state: {
    types: [
      {text: 'frauds.fields.operation.CHECK_PASSWORD', value: 'CHECK_PASSWORD'},
      {text: 'frauds.fields.operation.CHECK_PROMO_CODE', value: 'CHECK_PROMO_CODE'}
    ]
  },
  getters: {
    types: state => {
      return state.types;
    }
  }
}