import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import i18n from "./../i18n";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
    current: "fr"
  },
  theme: {
    themes: {
      light: {
        bg: "#f6f7f9",
        primary: "#000000",
        secondary: "#F1C761",
        third: "#F1E4D7",
        fourth: "#222325",
        fifth: "#2c2d30",
        danger: "#B71C1C",
        success: "#00BFA5",
        warning: "#FF6F00",
        orange: "#F76300",
        mtn: "#F4C317"
      }
    }
  }
});
