export default {
    namespaced: true,
    state: {
        types: [
            { text: 'resellers.fields.type.RETAILER', value: 'RETAILER' },
            { text: 'resellers.fields.type.DEPOT', value: 'SUB_RETAILER' }
        ]
    },
    getters: {
        types: (state) => {
            return state.types
        }
    }
}