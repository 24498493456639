export default {
  request: function(req, tokens) {
    if (typeof tokens !== "undefined") {
      let tokensArray = tokens.split("||");
      this.drivers.http.setHeaders.call(this, req, {
        Authorization: "Bearer " + tokensArray[0],
      });
    }
  },
  response: function(res) {
    const config = res.config;
    if (res.data) {
      if (config.url === "/api/v1/login") {
        // return token and refresh token
        if (res.data.access && res.data.refresh) {
          const tokens = res.data;
          return `${tokens.access}||${tokens.refresh}`;
        }
      } else if (config.url === "/api/v1/refresh-token") {
        if (res.data.access && res.data.refresh) {
          const tokens = res.data;
          return `${tokens.access}||${tokens.refresh}`;
        }
      }
    }
  },
};
