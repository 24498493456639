import Vue from 'vue'
import Vuex from 'vuex'
import notification from "./modules/notification";
import reseller from './modules/reseller';
import transaction from './modules/transaction';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    statuses: [
      { text: 'statuses.enabled', value: true },
      { text: 'statuses.disabled', value: false }
    ],
    roles: [
      { text: 'users.fields.role.ROLE_ADMIN', value: 'ROLE_ADMIN' },
      { text: 'users.fields.role.ROLE_DEPOT', value: 'ROLE_DEPOT' },
      { text: 'users.fields.role.ROLE_SELLER', value: 'ROLE_SELLER' }
    ]
  },
  getters: {
    statuses: (state) => {
      return state.statuses;
    },
    roles: (state) => {
      return state.roles;
    }
  },
  mutations: {
  },
  actions: {
    async request(store, params) {
      return Vue.axios({
        ...params
      });
    },
  },
  modules: {
    notification: notification,
    reseller: reseller,
    transaction: transaction
  }
})
