import VueJs from "vue";
import i18n from "./../i18n";

const Utils = {};

const operators = {
    orange: /^(237)?6((9[0-9]{7})|(5[5-9][0-9]{6})|(8[6-9][0-9]{6}))$/,
    mtn: /^(237)?6((7[0-9]{7})|(5[0-4][0-9]{6})|(8[0-4][0-9]{6}))$/,
    camtel: /^(237)?(242|243)[0-9]{6}$/,
    nexttel: /^(237)?6((6[0-9]{7})|(85[0-9]{6}))$/,
    all: /^(237)?((6(?:[5-9][0-9]{7}))|((242|243|620)[0-9]{6}))$/,
};

const defaultFont = "Lato";

const defaultLineChartOptions = {
    fontSize: 11,
    fontName: defaultFont,
    lineWidth: 2,
    backgroundColor: {
        fill: "#1E1E1E",
    },
    chartArea: {
        top: "15%",
        left: "5%",
        height: "60%",
        width: "90%",
    },
    height: 300,
    legend: {
        textStyle: {
            fontName: defaultFont,
            fontSize: 11,
            color: "#FFF",
        },
        position: "bottom",
    },
    tooltip: {
        textStyle: {
            fontName: defaultFont,
        },
    },
    titleTextStyle: {
        fontName: defaultFont,
        color: "#FFF",
    },
    vAxis: {
        textStyle: {
            fontName: defaultFont,
            fontSize: 11,
            color: "#FFF",
        },
        titleTextStyle: {
            fontName: defaultFont,
            fontSize: 11,
            color: "#FFF",
        },
    },
    hAxis: {
        textStyle: {
            fontName: defaultFont,
            fontSize: 11,
            color: "#FFF",
        },
        titleTextStyle: {
            fontName: defaultFont,
            fontSize: 11,
            color: "#FFF",
        },
    },
};

Utils.install = function(Vue) {
    Utils.daterangeAlign = function(dates) {
        if (!Array.isArray(dates)) return null;
        if (dates.length !== 2) return null;
        if (!dates.every((val) => val)) return null;

        let start = dates[0];
        let end = dates[1];
        if (Vue.moment(start, "YYYY-MM-DD").isAfter(end, "days")) {
            start = dates[1];
            end = dates[0];
        }
        return [start, end];
    };

    Utils.getFilenameFromContentDisposition = function(disposition) {
        const matches = disposition.match(
            /attachment;\s+filename=(\\)?"(?<filename>.*)(\\)?"/im
        );
        if (matches) {
            return matches.groups.filename;
        }
        return null;
    };

    Utils.truncate = function(fullStr, len, separator) {
        let strLen;

        if (!Array.isArray(len)) len = [len];
        if (/\s+/.test(fullStr)) {
            strLen = len[0];
        } else {
            strLen = len[1] ? len[1] : len[0];
        }

        if (fullStr.length <= strLen) return fullStr;

        separator = separator || "...";

        const sepLen = separator.length;
        const charsToShow = strLen - sepLen;
        const frontChars = Math.ceil(charsToShow / 2);

        return fullStr.substr(0, frontChars) + separator;
    };

    Utils.validateOperator = function(operator, value) {
        if (!operators[operator]) return false;
        return operators[operator].test(value);
    };

    Utils.humanFileSize = function(bytes, si = false, dp = 1) {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + " B";
        }

        const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        let u = -1;
        const r = 10 ** dp;

        do {
            bytes /= thresh;
            ++u;
        } while (
            Math.round(Math.abs(bytes) * r) / r >= thresh &&
            u < units.length - 1
        );

        return bytes.toFixed(dp) + " " + units[u];
    };

    Utils.pad = function pad(num, size) {
        if (parseInt(num) === 0) return 0;
        let s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    };

    Utils.getArrayByRangedate = function(
        startdate,
        enddate,
        format = "YYYY-MM-DD"
    ) {
        const startDateM = Vue.moment(startdate, format);
        const endDateM = Vue.moment(enddate, format);

        const arrayDaterange = [];
        for (
            let start = startDateM; startDateM.diff(endDateM, "days") <= 0; start.add(1, "days")
        ) {
            arrayDaterange.push(start.format("YYYY-MM-DD"));
        }
        return arrayDaterange;
    };

    Utils.parseChartHeader = function(header) {
        if (typeof header === "boolean") {
            return header ? i18n.t("btn.yes") : i18n.t("btn.no");
        }
        return header;
    };

    Utils.getParticipationsStatistics = function(
        datas,
        group,
        startdate,
        enddate
    ) {
        let chartHeaders = {};
        const arrayDate = Utils.getArrayByRangedate(startdate, enddate);
        const tableDatas = {};
        const chartDatas = {};
        const tableDatasParsed = {}

        for (const data of datas) {
            const { total, date, ...rest } = data;
            const head = Utils.parseChartHeader(rest[group.toLowerCase()]);

            chartHeaders[head] = head;

            if (!Vue.$objectPath.has(chartDatas, date)) {
                chartDatas[date] = {};
            }
            if (!Vue.$objectPath.has(chartDatas[date], head)) {
                chartDatas[date][head] = 0;
            }
            if (!Vue.$objectPath.has(tableDatas, date)) {
                tableDatas[date] = total;
            } else {
                tableDatas[date] += total;
            }
            chartDatas[date][head] += total;
        }

        chartHeaders = Object.values(chartHeaders);
        const chartDatasParsed = [
            [i18n.t("participations.fields.created.title2"), ...chartHeaders],
        ];

        for (const date of arrayDate) {
            const dt = [date];
            for (const header of chartHeaders) {
                if (!Vue.$objectPath.has(chartDatas, `${date}.${header}`)) {
                    dt.push(0);
                } else {
                    dt.push(chartDatas[date][header]);
                }
            }
            chartDatasParsed.push(dt);

            if (!Vue.$objectPath.has(tableDatas, date)) {
                tableDatasParsed[date] = 0;
            } else {
                tableDatasParsed[date] = tableDatas[date]
            }
        }

        return {
            chart: {
                data: chartDatasParsed,
                options: Vue._.merge({}, defaultLineChartOptions, {
                    title: i18n.t("participations.statistics.subtitle", {
                        group: i18n
                            .t("participations.statistics.group." + group)
                            .toLowerCase(),
                    }),
                    vAxis: {
                        title: i18n.t("participations.statistics.subtitle2"),
                    },
                    hAxis: {
                        title: i18n.t("participations.statistics.subtitle3"),
                    },
                }),
            },
            table: tableDatasParsed,
        };
    };

    Utils.saveFile = async function(
        filename,
        workbook,
        workbookType = "xlsx",
        type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        options = { base64: true }
    ) {
        const xls64 = await workbook[workbookType].writeBuffer(options);
        Vue.$filesaver(
            new Blob([xls64], {
                type: type,
            }),
            filename
        );
    };

    if (!Vue.$utils) {
        Vue.$utils = Utils;
        Object.defineProperties(Vue.prototype, {
            $utils: {
                get() {
                    return Utils;
                },
            },
        });
    }
};

VueJs.use(Utils);