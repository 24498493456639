import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import i18n from "./i18n";
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

/* import plugins */
import "./plugins/vueaxios";
import "./plugins/vueauth";
import "./plugins/vuelidate";
import "./plugins/vuelodash";
import "./plugins/vuemeta";
import "./plugins/vuemoment";
import "./plugins/vuefilesaver";
import "./plugins/vuegooglechart";
import "./plugins/vueobjectpath";
import "./plugins/vueexcel";
import "./plugins/vueutils";



new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
